.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
}
#root{
  height: 100%;
}
.printme {
  display: none;
}
.page-break {
  break-before: page;
}
@media print {
  .no-printme {
    display: none;
  }
  .printme {
    display: block;
  }
  .first-number {
    background-color: #1170aa !important;
  }
  .second-number {
    background-color: #1170aa !important;
  }
  .third-number {
    background-color: #1170aa !important;
  }
  .fourth-number {
    background-color: #1170aa !important;
  }
  .page-break {
    break-before: page;
  }
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .content {
    min-height: calc(100vh - 70px);
  }
  @import url("https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css");
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

.examples__block__map__tooltip {
  position: fixed;
  width: 200px;
  padding: 10px;
  border: 1px solid darkgray;
  background-color: white;
  z-index: 999;
}
.svg-map__location {
  fill: lightgrey;
}
.history {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
}
.history:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#wrapper {
  overflow-x: hidden;
}

.icon {
  content: url("../../imgs/logo_mi_negocio.png");
  height: 35px;
  display: block;
}

.flecha {
  content: url("../../imgs/flecha.png");
  width: 30px;
  display: block;
}

.flecha-left {
  content: url("../../imgs/flecha_left.png");
  width: 30px;
  display: block;
}

.whatsapp-icon {
  content: url("../../imgs/whatsapp.png");
  width: 30px;
  display: block;
}

.whatsapp-icon-2 {
  content: url("../../imgs/whatsapp.svg");
  width: 30px;
  display: block;
}

.adn-icon {
  content: url("../../imgs/adn-blanco.png");
  width: 30px;
  display: block;
}

.flecha-reverse {
  content: url("../../imgs/flecha-reverse.png");
  width: 30px;
  display: block;
}

.flecha-reverse-right {
  content: url("../../imgs/flecha-reverse-right.png");
  width: 30px;
  display: block;
}

.correo-icon-2 {
  content: url("../../imgs/correo\ 2.svg");
  width: 30px;
  display: block;
}

.llamadas-icon {
  content: url("../../imgs/llamadas.svg");
  width: 30px;
  display: block;
}

.forma-icon {
  content: url("../../imgs/forma.svg");
  width: 30px;
  display: block;
}

.estadistica-icon {
  content: url("../../imgs/estasdistica.svg");
  width: 30px;
  display: block;
}

.correo-icon {
  content: url("../../imgs/correo.svg");
  width: 30px;
  display: block;
}

.slider-container {
  width: 100%;
  height: 100%;

  /* Add this */
  position: fixed;
  top: 0;
  left: 0;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}
@media (max-width: 768px) {
  #sidebar-wrapper .list-group {
    width: 15rem;
  }
}
@media (min-width: 768px) {
  #sidebar-wrapper .list-group {
    width: 18rem;
  }
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -18rem;
  }
}
#fondo {
  background: url("../../imgs/formas.png") no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}
#fondo2 {
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(2, 150, 51, 0.8) 44%,
      rgba(2, 150, 51, 0.95) 100%
    ),
    url("../../imgs/correos.png") no-repeat;
  background-size: cover;
}

#fondo3 {
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(238, 90, 48, 0.8) 44%,
      rgba(238, 90, 48, 0.95) 100%
    ),
    url("../../imgs/llamadas.png") no-repeat;
  background-size: cover;
}

#fondo4 {
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(6, 133, 178, 0.5) 44%,
      rgba(6, 133, 178, 0.65) 100%
    ),
    url("../../imgs/whatsap-fondo.png") no-repeat;
  background-size: cover;
}
.slide .previous {
  display: none !important;
}
#fondo5 {
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(247, 152, 30, 0.6) 44%,
      rgba(247, 152, 30, 0.85) 100%
    ),
    url("../../imgs/formas.png") no-repeat;
  background-size: cover;
}

#fondo6 {
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(27, 68, 99, 0.8) 44%,
      rgba(27, 68, 99, 0.95) 100%
    ),
    url("../../imgs/estadistica.png") no-repeat;
  background-size: cover;
}
.react-tooltip {
  z-index: 999 !important;
}
.bg-test0 {
  background-color: #ee5a30 !important;
}
.bg-test1 {
  background-color: #25d366 !important;
}
.bg-test2 {
  background-color: #34b7f1 !important;
}
.bg-test3 {
  background-color: #34a853 !important;
}
.bg-test4 {
  background-color: #f7981e !important;
}
.bg-test5 {
  background-color: #174ea6 !important;
}
.bg-test6 {
  background-color: #3b5998 !important;
}
.bg-test7 {
  background-color: #8b9dc3 !important;
}
.bg-test8 {
  background-color: #fa3c4c !important;
}
.bg-test9 {
  background-color: #44bec7 !important;
}
* {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0px;
}

html,
body {
  height: 100vh;
  width: 100vw;
}

@media only screen and (min-width: 1024px) {
  html,
  body {
    font-family: Roboto;
    background: linear-gradient(
      135deg,
      rgba(55, 135, 183, 1) 0%,
      rgba(212, 40, 71, 1) 73%,
      rgba(169, 35, 117, 1) 100%
    );
    font-size: 14px;
    overflow-x: hidden;
  }
}
@media only screen and (max-width: 1024px) {
  html,
  body {
    font-family: Roboto;
    background: linear-gradient(
      135deg,
      rgba(55, 135, 183, 1) 0%,
      rgba(212, 40, 71, 1) 73%,
      rgba(169, 35, 117, 1) 100%
    );
    font-size: 14px;
    overflow-x: hidden;
  }
}

.login-page {
  padding: 5%;
  margin-top: 10px;
  position: relative;
}

.login-form {
  box-shadow: 0 0 27px 0 rgba(84, 84, 84, 0.62);
  background-color: whitesmoke !important;
  border-radius: 25px;
}

.login-header {
  background: url("../../imgs/portada-ini.png") no-repeat;
  background-size: 100%;
}

.container-login {
  width: 100%;
  position: relative;
  min-height: fit-content;
}
.tag-login {
  float: left;
  position: absolute;
  left: 0px;
  top: 25%;
  z-index: 1000;
  background-color: white;
  padding: 20px;
  border-bottom: solid 5px #1170AA;
  border-top: solid 5px #1170AA;
  border-right: solid 5px #1170AA;
}

.login-form-header {
  padding-top: 16px;
  background: url("../../imgs/img-ini-1.png") center top;

  @media only screen and (min-width: 1024px) {
    min-height: 488px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  @media only screen and (max-width: 1024px) {
    // min-height: 200px;
    //border-top-left-radius: 25px;
    //border-top-right-radius: 25px;
    display: none;
  }
}

.login-from-row {
  padding-top: 16px;
  padding-bottom: 16px;
}

.login-form-font-header {
  font-size: 30px;
  font-weight: bold;
}

.login-form-font-header span {
  color: #007c9b;
}

.react-select > div {
  margin: -1px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.mdb tbody tr td {
  text-align: center;
  font-size: 14px;
}
.mdb thead {
  text-align: center;
  font-size: 16px;
}

.online {
  text-decoration: none;
  cursor: pointer;
  color: #98012e;
  position: absolute;
  top: 9px;
  right: 15px;
  font-weight: bolder;
}
.pmsc-filter {
  width: 96%;
  height: 30px;
  position: relative;

  margin: 0 2%;
}
.pmsc-left ul {
  display: block;

  margin: 0 2%;
  padding: 0;

  width: 96%;
  max-height: calc(100% - 30px);

  border: solid 1px #e2e2e2;
  border-top: none;

  list-style-type: none;

  overflow-x: hidden;
  overflow-y: scroll;
}

.pmsc-left ul li {
  width: 77%;

  margin: 0;
  padding: 8px 20% 14px 3%;

  border-bottom: solid 1px #e2e2e2;

  position: relative;

  cursor: pointer;
}

.pmsc-left ul li.activ,
.pmsc-left ul li:hover {
  background-color: #ededed;
}

.pmsc-left ul li:last-child {
  border-bottom: none;
}

.pmsc-f-i,
.pmsc-f-b {
  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;

  border: none;

  display: inline-block;

  vertical-align: top;
}

.pmsc-f-i {
  width: 90%;
  height: 29px;

  left: 0;

  font-family: "Exo";
  font-size: 16px;
  line-height: 29px;
  outline: none;
  border: none;

  border-bottom: solid 1px #98012e;
  border-left: none;
  border-top: none;
  border-right: none;

  padding: 5px;
  background-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pmsc-f-b {
  width: calc(10% + 2px);
  height: 30px;

  right: -2px;

  background-color: #98012e;

  color: white;
}

.pmsc-f-b i {
  font-size: 20px;
  line-height: 29px;
}

.pmsc-loc {
  position: absolute;

  color: #98012e;

  right: 7%;
  top: calc(50% - 17px);
}

.pmsc-loc i {
  font-size: 35px;
  line-height: 35px;

  transition: all 0.1s ease-out;
}

.pmsc-left ul li.activ .pmsc-loc i {
  font-size: 55px;
  line-height: 55px;

  margin: -12px -8px 0 0;
}

.pmsc-title {
  font-size: 16px;
}

.pmsc-address {
  font-size: 10px;

  padding: 9px 0;

  color: #707070;
}

.pmsc-phone {
  font-size: 12px;
}

.pmsc-phone a i,
.pmsc-phone span i {
  font-size: 13px;

  margin: 0;
  padding: 0;

  line-height: 12px;
}

.pmsc-phone span i {
  font-size: 22px;

  margin: 0;
  padding: 0;

  line-height: 12px;

  display: inline-block;
}

.pmsc-phone a,
.pmsc-phone span {
  display: inline-block;
  background-color: #98012e;

  vertical-align: middle;

  padding: 4px 12px;
  margin: 0 6px 0 0;

  color: #fff;

  text-decoration: none;

  -webkit-box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.pmsc-phone span {
  /*display: none;*/

  margin: 0;

  line-height: 0px;
  padding: 8px 11px;
}
.pmsc-phone a:hover,
.pmsc-phone span:hover {
  background-color: #e10045;
}
.pmsc-phone a {
  margin-bottom: 1em;
}

.container1 {
  position: relative;
  width: 50%;
}

.image1 {
  display: block;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.overlay1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 90%;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 30px;
  //background-color: #008CBA;
  opacity: 0.8;
}

.container1:hover .overlay1 {
  opacity: 1;
}

.text1 {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
[data-star] {
  text-align: left;
  font-style: normal;
  display: inline-block;
  position: relative;
  unicode-bidi: bidi-override;
}

[data-star]::before {
  display: block;
  content: "★★★★★";
  color: #eee;
}

[data-star]::after {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  width: 0;
  color: #ff8c00;
  overflow: hidden;
  height: 100%;
}

[data-star^="0.1"]::after {
  width: 2%;
}

[data-star^="0.2"]::after {
  width: 4%;
}

[data-star^="0.3"]::after {
  width: 6%;
}

[data-star^="0.4"]::after {
  width: 8%;
}

[data-star^="0.5"]::after {
  width: 10%;
}

[data-star^="0.6"]::after {
  width: 12%;
}

[data-star^="0.7"]::after {
  width: 14%;
}

[data-star^="0.8"]::after {
  width: 16%;
}

[data-star^="0.9"]::after {
  width: 18%;
}

[data-star^="1"]::after {
  width: 20%;
}

[data-star^="1.1"]::after {
  width: 22%;
}

[data-star^="1.2"]::after {
  width: 24%;
}

[data-star^="1.3"]::after {
  width: 26%;
}

[data-star^="1.4"]::after {
  width: 28%;
}

[data-star^="1.5"]::after {
  width: 30%;
}

[data-star^="1.6"]::after {
  width: 32%;
}

[data-star^="1.7"]::after {
  width: 34%;
}

[data-star^="1.8"]::after {
  width: 36%;
}

[data-star^="1.9"]::after {
  width: 38%;
}

[data-star^="2"]::after {
  width: 40%;
}

[data-star^="2.1"]::after {
  width: 42%;
}

[data-star^="2.2"]::after {
  width: 44%;
}

[data-star^="2.3"]::after {
  width: 46%;
}

[data-star^="2.4"]::after {
  width: 48%;
}

[data-star^="2.5"]::after {
  width: 50%;
}

[data-star^="2.6"]::after {
  width: 52%;
}

[data-star^="2.7"]::after {
  width: 54%;
}

[data-star^="2.8"]::after {
  width: 56%;
}

[data-star^="2.9"]::after {
  width: 58%;
}

[data-star^="3"]::after {
  width: 60%;
}

[data-star^="3.1"]::after {
  width: 62%;
}

[data-star^="3.2"]::after {
  width: 64%;
}

[data-star^="3.3"]::after {
  width: 66%;
}

[data-star^="3.4"]::after {
  width: 68%;
}

[data-star^="3.5"]::after {
  width: 70%;
}

[data-star^="3.6"]::after {
  width: 72%;
}

[data-star^="3.7"]::after {
  width: 74%;
}

[data-star^="3.8"]::after {
  width: 76%;
}

[data-star^="3.9"]::after {
  width: 78%;
}

[data-star^="4"]::after {
  width: 80%;
}

[data-star^="4.1"]::after {
  width: 82%;
}

[data-star^="4.2"]::after {
  width: 84%;
}

[data-star^="4.3"]::after {
  width: 86%;
}

[data-star^="4.4"]::after {
  width: 88%;
}

[data-star^="4.5"]::after {
  width: 90%;
}

[data-star^="4.6"]::after {
  width: 92%;
}

[data-star^="4.7"]::after {
  width: 94%;
}

[data-star^="4.8"]::after {
  width: 96%;
}

[data-star^="4.9"]::after {
  width: 98%;
}

[data-star^="5"]::after {
  width: 100%;
}
